// Chatbot section
@mixin form-component-style {
  border-radius: 0.75rem;
  padding: 0.6em;
  font-size: 1em;
}

@mixin chat-font-styles {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 1rem !important;
  @media screen and (min-width: 768px) {
    font-size: 18px;
    line-height: 30px;
  }
}

@mixin bot {
  color: $chat-gray !important;
  align-self: flex-start;
}

@mixin user {
  color: $chat-gray;
  align-self: flex-end;
}

.message-container ol,
ul,
li {
  @include chat-font-styles;
}

.message-container p {
  margin-bottom: 0.5rem !important;
}

.message-container {
  @media screen and (min-width: 768px) {
    padding-inline: 1rem;
    padding-top: 1rem;
  }
  word-break: break-word;
  overflow-wrap: break-word;
}

.message-container h3 {
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  //   color: $primary;
  @media screen and (min-width: 768px) {
    font-size: 25px !important;
    line-height: 35px !important;
  }
  @media screen and (min-width: 992px) {
    font-size: 30px !important;
    line-height: 40px !important;
  }
}

.bot-container {
  margin-left: 1rem;
  border-radius: 15px;
  background-color: $chat-green;
}

.user-chat {
  @include user;
  margin-right: 1rem;
  border-radius: 15px;
  background-color: $chat-pink;
}

.bot-chat {
  @include bot;
  border-radius: 15px;
}
.localy-generated-chat {
  @include bot;
  border-radius: 15px;
  background-color: #e6e7e8;
}
.avatars {
  margin-top: 5px;
  height: 25px;
  border-radius: 50%;
}

.chat-container {
  height: calc(100vh - 80px);
}

.chat-input-container {
  background-color: $white;
  border: 0.5px solid #393e46;
  margin-bottom: 1vh;
  @include form-component-style;
}

.chat-input-container textarea {
  border: none;
  outline: none;
  padding: 5px;
  resize: none; /* disable resizing */
  flex-grow: 1;
  overflow: auto;
  min-height: 30px;
  max-height: 150px;
}

.chat-input {
  width: 100%;
  border: none;
  background-color: transparent;
  &:focus {
    outline: none;
  }
}

.chat-button {
  border: 0;
  background-color: #9b258f;
  border-radius: 0.475rem;
  padding-inline: 0.75rem;
  height: 30px;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}
/* styling the scrollbar track (the part the thumb moves along) */
.chat-input::-webkit-scrollbar-track {
  background-color: transparent;
}

/* styling the scrollbar thumb (the part that's draggable) */
.chat-input::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 8px;
}

/* styling the scrollbar itself (width, etc.) */
.chat-input::-webkit-scrollbar {
  width: 8px;
}

// Chats
$standard-padding: 0.6rem;
$bubble-border-radius: 17px;

.bot-chat-container {
  padding: 1rem;
  background-color: $chat-green;
  border-radius: $bubble-border-radius;
}

.user-chat-container {
  padding: 1rem;
  background-color: $chat-pink;
  border-radius: $bubble-border-radius;
}

.bot-loading {
  width: fit-content;

  .pictograph-custom-image {
    cursor: pointer;
    border: 5px solid transparent;
  }

  .selected-image-bg-color {
    border: 5px solid $primary;
  }

  .selected-image-tick {
    top: -6px;
    right: 2px;
  }
}
.input-container {
  padding-left: 1rem;
  border-radius: 8px !important;
  background-color: $white !important;
  border: 1px solid $input-border !important;
}

.input-field {
  height: 30px;
  background-color: rgb(255, 255, 255);
  color: $white;
}

// sugestion bubble
.suggestion-bubble-container {
  width: 100%;
}

.suggestion-bubble-button {
  border: 1px solid #00000026;
  border-radius: 0.75rem;
  color: $primary-black;
  cursor: pointer;
  :hover {
    background-color: #ececec;
    border-radius: 0.75rem;
  }
  @media screen and (min-width: 992px) {
    width: 49%;
  }
}

.suggestion-bubble-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.side-panel-container {
  top: 70px;
  left: 7px;
}

.side-panel {
  width: 280px;
  padding-inline: 15px;
  padding-block: 4px;
  background: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.slider-container {
  margin: 10px 0;
}

.slider-container label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
}

.slider {
  width: 100%;
  margin: 5px 0 20px 0;
  -webkit-appearance: none;
  appearance: none;
  height: 5px;
  border-radius: 5px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: $primary;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: $primary;
  cursor: pointer;
}

/* For Firefox */
* {
  scrollbar-width: thin; /* 'auto' or 'thin' */
  scrollbar-color: $primary #f1f1f1; /* thumb and track color */
}

/* For Chrome, Edge, and Safari */
.active-asset-selector::-webkit-scrollbar {
  height: 4px;
}

.active-asset-selector::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.active-asset-selector::-webkit-scrollbar-thumb {
  background: $primary;
}

.active-asset-selector::-webkit-scrollbar-thumb:hover {
  background: $primary;
}

.active-asset-selector button {
  background-color: #f3efef;
  color: #6b6868;
  border: none;
  padding: 5px 15px;
  border-radius: 15px;
  white-space: nowrap;
  margin: 0 5px;
  transition: background-color 0.3s; /* Smooth background color transition */
}

.active-asset-selector button.active {
  background-color: $primary;
  color: #f3efef;
}

@keyframes wobble {
  0%,
  100% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-25deg); /* Increased from -15deg to -25deg */
  }
  50% {
    transform: rotate(25deg); /* Increased from 15deg to 25deg */
  }
  75% {
    transform: rotate(-25deg); /* Increased from -15deg to -25deg */
  }
}

.wobble-effect {
  display: inline-block;
  animation: wobble 1s infinite; /* Adjust the timing as necessary */
  font-size: 24px !important;
}

/* Default font size for small screens (e.g., mobile) */
.font-size-small {
    font-size: 10px;
}

/* Media query for big screens */
@media screen and (min-width: 768px) {
    .font-size-small {
        font-size: 16px;
    }
}
