.custom-tooltip {
  background-color: $white;
  padding: 12px;
  border-radius: 4px;
  font-size: 12px;
  border: 1px solid $teal;
}

.custom-label {
  font-family: 'Lexend';
  font-size: 10px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  @media screen and (min-width: 546px) {
    font-size: 16px;
    line-height: 16px;
  }
}

.custom-label-funds-transfer {
  font-family: 'SketchFlow Print' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-size: 10px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  @media screen and (min-width: 546px) {
    font-size: 16px;
    line-height: 16px;
  }
}

.custom-tick-label {
  font-family: 'Lexend';
  font-size: 8px;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  @media screen and (min-width: 768px) {
    font-size: 11.8px;
  }
  @media screen and (min-width: 546px) and (max-width: 767px) {
    font-size: 9.5px;
  }
}

.custom-label-bold {
  font-weight: bold;
}

.gray-text {
  color: $gray-600-dark !important;
}
.label-loss {
  color: $tertiary !important;
}
.label-gain {
  color: $senary !important;
}
.label-black {
  color: $black !important;
}
.label-contributions {
  color: $quinary !important;
}
.label-initial-deposit {
  color: $quaternary !important;
}
.label-bold {
  font-weight: 600 !important;
  letter-spacing: 0.5px;
}
.label-main {
  color: $teal-blue !important;
}
